<template>
  <v-row class="mt-5 mx-1">
    <v-col cols="12">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Despachos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="openCreateModal">Crear Despacho</v-btn>
          <v-select
            v-if="companies.length"
            v-model="selectedCompany"
            :items="companies"
            item-value="id"
            item-text="nombre"
            dense
            solo
            prepend-inner-icon="mdi-domain"
            class="mx-5"
            light
            hide-details
            style="max-width: 300px"
          >
          </v-select>
        </v-toolbar>

        <v-card-text>
          <v-simple-table fixed-header height="600px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Despacho</th>
                  <th class="text-left">Numero de plantas</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in filteredDispatches"
                  :key="item.iddespacho"
                  @click="openModal(item)"
                  style="cursor: pointer"
                >
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.plantas.length }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Modal para mostrar las plantas -->
    <v-dialog v-model="plantsModalVisible" max-width="800px">
      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>{{ selectedDispatch?.nombre }} - Plantas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveAndClose">Guardar y Salir</v-btn>
          <v-btn color="secondary" text @click="plantsModalVisible = false">Cerrar</v-btn>
        </v-toolbar>

        <v-card-text>
          <v-checkbox
            v-for="planta in plants"
            :key="planta.idplanta"
            v-model="selectedPlants"
            :label="planta.nombre"
            :value="planta.idplanta"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal para crear un nuevo despacho -->
    <v-dialog v-model="createModalVisible" max-width="600px">
      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>Crear Nuevo Despacho</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveNewDispatch">Guardar</v-btn>
          <v-btn color="secondary" text @click="createModalVisible = false">Cancelar</v-btn>
        </v-toolbar>

        <v-card-text>
          <v-text-field v-model="newDispatchName" label="Nombre del Despacho" required />
          <v-checkbox
            v-for="planta in plants"
            :key="planta.idplanta"
            v-model="newDispatchPlants"
            :label="planta.nombre"
            :value="planta.idplanta"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { api } from '@/utils'

export default {
  name: 'Dispatches',
  data: () => ({
    loading: true,
    companies: [],
    selectedCompany: null,
    dispatches: [],
    plants: [],
    filteredDispatches: [],
    plantsModalVisible: false,
    createModalVisible: false,
    selectedDispatch: null,
    selectedPlants: [],
    newDispatchName: '',
    newDispatchPlants: []
  }),
  mounted() {
    this.companies = this.$session.get('companies') || []
    this.selectedCompany = this.companies[0] || null
    this.getAllDispatches()
    this.getAllPlants()
  },
  watch: {
    selectedCompany(newCompany) {
      this.filterDispatches(newCompany)
    }
  },
  methods: {
    async getAllDispatches() {
      const res = await api('get', 'getAllDispatches')
      this.dispatches = res?.data || []
      this.filterDispatches(this.selectedCompany)
    },
    async getAllPlants() {
      const res = await api('get', 'getAllPlants')
      this.plants = res?.data || []
    },
    filterDispatches(selectedCompany) {
      if (!selectedCompany || !this.dispatches.length) {
        this.filteredDispatches = []
        return
      }
      this.filteredDispatches = this.dispatches.filter((dispatch) => dispatch.idempresa === selectedCompany.id)
    },
    openModal(dispatch) {
      this.selectedPlants = dispatch.plantas.map((planta) => planta.idplanta)
      this.selectedDispatch = dispatch
      this.plantsModalVisible = true
    },
    openCreateModal() {
      this.newDispatchName = ''
      this.newDispatchPlants = []
      this.createModalVisible = true
    },
    async saveNewDispatch() {
      try {
        const data = {
          data: {
            nombre: this.newDispatchName,
            empresa: this.selectedCompany.id,
            plantIds: this.newDispatchPlants
          }
        }
        await api('post', 'getAllDispatches', data)
        this.getAllDispatches()
        this.createModalVisible = false
      } catch (e) {
        this.createModalVisible = false
      }
    },
    async saveAndClose() {
      try {
        const data = {
          data: {
            iddespacho: this.selectedDispatch.iddespacho,
            plantIds: this.selectedPlants
          }
        }
        await api('post', 'postUpdateDispatchPlants', data)
        this.getAllDispatches()
        this.plantsModalVisible = false
      } catch (e) {
        this.plantsModalVisible = false
      }
    }
  }
}
</script>

<style scoped>
tr {
  cursor: pointer;
}

.v-toolbar {
  justify-content: space-between;
}
</style>
